import { AnimatePresence, motion } from 'framer-motion';
import '../../../styles/pages/tara/history/taraHistorySidebar.scss';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import CustomSVGs from '../../../ui/customSVGs/CustomSVGs';
import truncate from '../../../lib/helper-functions/truncate';
import { arrowHeadRightSVG } from '../../../lib/allCustomSVGs';
import {
  setAllChatsHistory,
  setCurrentChatID,
  setIsBotTyping,
} from '../../../store/reducers/chatSlice';
import { useEffect, useState } from 'react';
import { Chat } from '../../../interfaces/taraInterfaces';
import getAllChatsByDateArray from '../../../lib/helper-functions/getAllChatsByDateArray';
import { useLocation, useNavigate } from 'react-router-dom';
import LoadingSpinner from '../../../ui/loadingSpinner/LoadingSpinner';

interface Props {
  isSidebarMinimized: boolean;
}

const TaraHistorySidebar = ({ isSidebarMinimized }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname;

  const allChatsHistory = useSelector(
    (state: RootState) => state.chat.allChatsHistory
  );
  const currentChatID = useSelector(
    (state: RootState) => state.chat.currentChatID
  );
  const historyLoading = useSelector(
    (state: RootState) => state.chat.historyLoading
  );
  const isChatHistory = allChatsHistory?.length > 0;

  const [allChats, setAllChats] = useState<Chat[]>([]);
  const [dropdownStates, setDropdownStates] = useState<{
    [key: string]: boolean;
  }>({});

  const toggleDropdown = (chatId: string | number, e: React.MouseEvent) => {
    e.stopPropagation();
    setDropdownStates((prevState) => ({
      ...prevState,
      [chatId]: !prevState[chatId],
    }));
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      let clickedOutside = true;
      for (const chatId in dropdownStates) {
        const dropdownElement = document.getElementById(`dropdown-${chatId}`);
        if (dropdownElement && dropdownElement.contains(event.target as Node)) {
          clickedOutside = false;
          break;
        }
      }
      if (clickedOutside) {
        const newDropdownStates = Object.keys(dropdownStates).reduce(
          (acc, chatId) => {
            acc[chatId] = false;
            return acc;
          },
          {} as { [key: string]: boolean }
        );
        setDropdownStates(newDropdownStates);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownStates]);

  useEffect(() => {
    const formattedChatHistory = getAllChatsByDateArray(allChatsHistory);
    setAllChats(formattedChatHistory);
  }, [allChatsHistory]);

  const onHistoryItemClick = (chatId: string | number) => {
    if (currentPath !== '/tara') {
      const chatRoute = '/tara/chat/' + chatId;
      navigate(chatRoute);
    }
    if (currentChatID === chatId) return;
    const selectedChat = allChatsHistory?.find(
      (chat) => chat?.chatId === chatId
    );
    if (selectedChat) {
      dispatch(setCurrentChatID(selectedChat?.chatId));
      dispatch(setIsBotTyping(false));
    }
  };

  const onDeleteHistoryItem = (chatId: string | number) => {
    const updatedChatsHistory = allChatsHistory?.filter(
      (chat) => chat?.chatId !== chatId
    );
    dispatch(setAllChatsHistory(updatedChatsHistory));
    if (currentChatID === chatId) {
      dispatch(setCurrentChatID(null));
      dispatch(setIsBotTyping(false));
    }
  };

  const renderHistoryItems = () => {
    return (
      <div className='taraHistorySidebar__itemContainer'>
        {historyLoading === 'pending' ? (
          <LoadingSpinner />
        ) : (
          <AnimatePresence>
            {allChats.map((chat, i) => {
              if (i > 4) return null;
              const historyItemTitle =
                truncate(chat.chatMessages[0]?.message, 25) || '';
              const itemId = chat.chatId || '';
              return (
                <motion.li
                  key={`${chat?.chatId}-${i}`}
                  onClick={() => onHistoryItemClick(itemId)}
                  title={historyItemTitle}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.2, ease: 'easeOut' }}
                  data-sub_page_link_holder={
                    itemId === currentChatID &&
                    currentPath.includes('tara/chat')
                      ? 'true'
                      : 'false'
                  }
                  className='taraHistorySidebar--linkItem'
                  style={{ cursor: 'pointer', padding: '0.5rem' }}
                >
                  <div className='taraHistorySidebar--linkItem-left'>
                    <span style={{ display: 'block' }}>{historyItemTitle}</span>

                    {/* Hidden until backend is supported */}
                    {/* <div
                      className='taraHistorySidebar--linkItem-left-svg'
                      onClick={(e) => toggleDropdown(itemId, e)}
                    >
                      <CustomSVGs svg={menuDotsVerticalSVG} />
                      {dropdownStates[itemId] && (
                        <TaraHistoryItemDropdown
                          onListItemClick={() => onDeleteHistoryItem(itemId)}
                          dropdownId={`dropdown-${itemId}`}
                        />
                      )}
                    </div> */}
                  </div>
                </motion.li>
              );
            })}
          </AnimatePresence>
        )}
        {allChats.length > 0 && (
          <div
            onClick={() => navigate('/tara/search')}
            className='taraHistorySidebar--viewAll'
          >
            <span>{t('ViewAll')}</span>
            <CustomSVGs svg={arrowHeadRightSVG} />
          </div>
        )}
      </div>
    );
  };

  return (
    <div
      data-is-sidebar-minimized-history={isSidebarMinimized.toString()}
      className='taraHistorySidebar'
    >
      {isChatHistory ? (
        renderHistoryItems()
      ) : (
        <div className='taraHistorySidebar--empty'>{t('NoChatHistory')}</div>
      )}
    </div>
  );
};

export default TaraHistorySidebar;
