import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  aiGenerateSVG,
  arrowHeadLeftSVG,
  settingsPlusSVG,
  ToggleSideBarSVG,
  trackingSVG,
  userSVG,
} from '../../lib/allCustomSVGs';
import '../../styles/components/sidebar/sidebarTara.scss';
import CustomSVGs from '../../ui/customSVGs/CustomSVGs';
import { RootState } from '../../store/store';
import { useTranslation } from 'react-i18next';
import { useEffect, useRef, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import {
  linksDashboard,
  linksRecommendations,
  linksTara,
  linksTracking,
} from '../../lib/constants/sidebarLinks';
import { allRoutes } from '../../lib/constants/route';
import SidebarProfileTheme2 from './SidebarProfileTheme2';
import truncate from '../../lib/helper-functions/truncate';
import TaraHistorySidebar from '../../pages/tara/history/TaraHistorySidebar';
import TaraCreateNewChat from '../../pages/tara/history/TaraCreateNewChat';
import {
  setCurrentChatID,
  setIsBotTyping,
} from '../../store/reducers/chatSlice';

interface Items {
  icon: string | JSX.Element;
  iconType: string;
  title: string;
  link: string;
  urlName: string;
  level: number;
  specialURL?: string;
  isHidden?: boolean;
}
interface Props {
  changeSidebarSize: () => void;
  isSidebarMinimized: boolean;
  mainSubTitle: string;
  items: Items[];
}

const SidebarTara = ({
  mainSubTitle,
  items,
  isSidebarMinimized,
  changeSidebarSize,
}: Props) => {
  // const userCompany = useSelector((state: RootState) => state.user.company);
  const isDarkMode = useSelector((state: RootState) => state.UI.theme.dark);
  const userProfile = useSelector((state: RootState) => state.user.profile);
  const staticURL = useSelector((state: RootState) => state.app.staticURL);
  const merchantTheme = useSelector(
    (state: RootState) => state.user.merchant.config
  );
  const [renderingItems, setRenderingItems] = useState<Items[]>([]);
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname;
  const { t } = useTranslation();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [openedNestedMenuLinks, setOpenedNestedMenuLinks] = useState<string[]>(
    []
  );
  const [activeNestedMenuLink, setActiveNestedMenuLink] = useState('');
  const dropdownRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();

  // Toggle dropdown open/close state
  const toggleDropdown = () => {
    setIsDropdownOpen((prev) => !prev);
  };

  // Effect to handle click outside to close the dropdown
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsDropdownOpen(false);
      }
    };

    // Attach event listener to the document
    document.addEventListener('mousedown', handleClickOutside);

    // Clean up the event listener on component unmount
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const nestedMenuLink = currentPath.split('/')[1];
    setOpenedNestedMenuLinks([...openedNestedMenuLinks, nestedMenuLink]);
    setActiveNestedMenuLink(nestedMenuLink);
  }, [currentPath]);

  useEffect(() => {
    window.scrollTo(0, 0);
    const selectedPathObj = items.find((item) => item.link === currentPath);

    const selectedPathLevel = selectedPathObj?.level;

    if (selectedPathLevel === 1) {
      const level_1_Items = items?.filter(
        (item) => item.level === selectedPathLevel
      );
      setRenderingItems(level_1_Items);
    } else if (selectedPathLevel && selectedPathLevel > 1) {
      if (currentPath.includes('tracking')) {
        const level_1_Items = items?.filter((item) => item.level === 1);
        setRenderingItems(level_1_Items);
      } else {
        const otherItems = items?.filter(
          (item) => item.level === selectedPathLevel
        );
        const selectedLinkSplitArr = selectedPathObj.link.split('/');
        const selectedLinkCommonPath =
          selectedLinkSplitArr[selectedLinkSplitArr.length - 2];

        const result = otherItems?.filter((item) => {
          const otherLinkSplitArr = item.link.split('/');
          const otherLinkCommonPath =
            otherLinkSplitArr[otherLinkSplitArr.length - 2];
          if (otherLinkCommonPath === selectedLinkCommonPath) {
            return item;
          } else return null;
        });

        setRenderingItems(result);
      }
    } else {
      const otherLinkSplitArr = currentPath.split('/');
      const otherLinkSplitArrLength = otherLinkSplitArr.length;
      if (otherLinkSplitArrLength > 1) {
        if (otherLinkSplitArr[otherLinkSplitArrLength - 1] === 'add') {
          const selectedLink = [...otherLinkSplitArr].slice(0, -1).join('/');
          console.log(selectedLink);
        } else if (
          otherLinkSplitArr[otherLinkSplitArrLength - 2] === 'edit' ||
          otherLinkSplitArr[otherLinkSplitArrLength - 2] === 'view' ||
          otherLinkSplitArr[otherLinkSplitArrLength - 2] === 'delete'
        ) {
          const selectedLink = [...otherLinkSplitArr].slice(0, -3).join('/');
          console.log(selectedLink);
        }
      }
    }
  }, [currentPath, items]);

  const mainSectionView = () => {
    if (!currentPath.includes('tara')) {
      return (
        <AnimatePresence>
          {renderingItems?.map((item, i) => {
            if (item?.isHidden) return null;
            return (
              <motion.li
                title={t(item.title)}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.2, ease: 'easeOut' }}
                // transition={{ type: 'spring', damping: 20, stiffness: 150 }}
                onClick={() => {
                  navigate(item.link);
                }}
                data-sub_page_link_holder={
                  item.specialURL
                    ? currentPath === item.specialURL
                    : currentPath.includes(item.urlName)
                    ? 'true'
                    : 'false'
                }
                key={i}
                className='sidebarTara__bottom--linkItem'
              >
                <div className='sidebarTara__bottom--linkItem-left'>
                  {item?.iconType === 'svg' ? (
                    <CustomSVGs svg={item.icon} />
                  ) : (
                    <img
                      src={item.icon as any}
                      className='subPageLinkHolderItems__img'
                      alt='icon'
                    />
                  )}
                  <motion.span
                    initial={{ opacity: 0 }}
                    animate={{ opacity: isSidebarMinimized ? 0 : 1 }}
                    transition={{ duration: 1 }}
                  >
                    {t(item.title)}
                  </motion.span>
                </div>
              </motion.li>
            );
          })}
        </AnimatePresence>
      );
    } else {
      return null;
    }
  };

  const taraSectionView = () => {
    if (!allRoutes['/tara']) return null;

    if (activeNestedMenuLink !== 'settings') {
      return (
        <>
          <AnimatePresence>
            <motion.li
              title={t('Tara')}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.2, ease: 'easeOut' }}
              // transition={{ type: 'spring', damping: 20, stiffness: 150 }}
              onClick={() => {
                if (openedNestedMenuLinks.includes('tara')) {
                  setOpenedNestedMenuLinks(
                    openedNestedMenuLinks.filter((item) => item !== 'tara')
                  );
                } else {
                  setOpenedNestedMenuLinks([...openedNestedMenuLinks, 'tara']);
                }
              }}
              key={'Tara'}
              className='sidebarTara__bottom--linkItem'
              data-sub_page_link_holder={
                activeNestedMenuLink === 'tara' &&
                !openedNestedMenuLinks.includes('tara')
                  ? 'true'
                  : 'false'
              }
              data-is-nested-link-open={
                !openedNestedMenuLinks.includes('tara') ? 'true' : 'false'
              }
            >
              <div className='sidebarTara__bottom--linkItem-left'>
                <CustomSVGs svg={aiGenerateSVG} />
                <motion.span
                  initial={{ opacity: 0 }}
                  animate={{ opacity: isSidebarMinimized ? 0 : 1 }}
                  transition={{ duration: 1 }}
                >
                  {t('Tara')}
                </motion.span>
              </div>
              <div className='sidebarTara__bottom--linkItem-right'>
                <CustomSVGs svg={arrowHeadLeftSVG} />
              </div>
            </motion.li>
            {openedNestedMenuLinks.includes('tara') &&
              linksTara.map((item, i) => {
                if (item?.isHidden) return null;
                return (
                  <motion.li
                    key={i}
                    title={t(item.title)}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.2, ease: 'easeOut' }}
                    // transition={{ type: 'spring', damping: 20, stiffness: 150 }}
                    onClick={() => {
                      if (item.link === '/tara') {
                        dispatch(setCurrentChatID(null));
                        dispatch(setIsBotTyping(false));
                      }
                      navigate(item.link);
                    }}
                    data-sub_page_link_holder={
                      currentPath.includes(item.urlName) &&
                      currentPath.includes('tara')
                        ? 'true'
                        : 'false'
                    }
                    data-is_nested_link_holder={'true'}
                    className='sidebarTara__bottom--linkItem'
                  >
                    <div className='sidebarTara__bottom--linkItem-left'>
                      <CustomSVGs svg={item.icon} />

                      <motion.span
                        initial={{ opacity: 0 }}
                        animate={{ opacity: isSidebarMinimized ? 0 : 1 }}
                        transition={{ duration: 1 }}
                      >
                        {t(item.title)}
                      </motion.span>
                    </div>
                  </motion.li>
                );
              })}
          </AnimatePresence>
        </>
      );
    } else {
      return null;
    }
  };

  const dashboardSectionView = () => {
    if (!allRoutes['/dashboard']) return null;

    if (activeNestedMenuLink !== 'settings') {
      return (
        <AnimatePresence>
          {linksDashboard?.map((item, i) => {
            return (
              <motion.li
                title={t(item.title)}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.2, ease: 'easeOut' }}
                // transition={{ type: 'spring', damping: 20, stiffness: 150 }}
                onClick={() => {
                  navigate(item.link);
                }}
                data-sub_page_link_holder={
                  item.specialURL
                    ? currentPath === item.specialURL
                    : currentPath.includes(item.urlName)
                    ? 'true'
                    : 'false'
                }
                key={i}
                className='sidebarTara__bottom--linkItem'
              >
                <div className='sidebarTara__bottom--linkItem-left'>
                  {item?.iconType === 'svg' ? (
                    <CustomSVGs svg={item.icon} />
                  ) : (
                    <img
                      src={item.icon as any}
                      className='subPageLinkHolderItems__img'
                      alt='icon'
                    />
                  )}
                  <motion.span
                    initial={{ opacity: 0 }}
                    animate={{ opacity: isSidebarMinimized ? 0 : 1 }}
                    transition={{ duration: 1 }}
                  >
                    {t(item.title)}
                  </motion.span>
                </div>
              </motion.li>
            );
          })}
        </AnimatePresence>
      );
    } else {
      return null;
    }
  };

  const trackingSectionView = () => {
    if (!allRoutes['/tracking']) return null;

    if (activeNestedMenuLink !== 'settings') {
      return (
        <>
          <AnimatePresence>
            <motion.li
              title={t('AITracking')}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.2, ease: 'easeOut' }}
              // transition={{ type: 'spring', damping: 20, stiffness: 150 }}
              onClick={() => {
                if (openedNestedMenuLinks.includes('tracking')) {
                  setOpenedNestedMenuLinks(
                    openedNestedMenuLinks.filter((item) => item !== 'tracking')
                  );
                } else {
                  setOpenedNestedMenuLinks([
                    ...openedNestedMenuLinks,
                    'tracking',
                  ]);
                }
              }}
              key={'AITracking'}
              className='sidebarTara__bottom--linkItem'
              data-sub_page_link_holder={
                activeNestedMenuLink === 'tracking' &&
                !openedNestedMenuLinks.includes('tracking')
                  ? 'true'
                  : 'false'
              }
              data-is-nested-link-open={
                !openedNestedMenuLinks.includes('tracking') ? 'true' : 'false'
              }
            >
              <div className='sidebarTara__bottom--linkItem-left'>
                <CustomSVGs svg={trackingSVG} />
                <motion.span
                  initial={{ opacity: 0 }}
                  animate={{ opacity: isSidebarMinimized ? 0 : 1 }}
                  transition={{ duration: 1 }}
                >
                  {t('AITracking')}
                </motion.span>
              </div>
              <div className='sidebarTara__bottom--linkItem-right'>
                <CustomSVGs svg={arrowHeadLeftSVG} />
              </div>
            </motion.li>
            {openedNestedMenuLinks.includes('tracking') &&
              linksTracking.map((item, i) => {
                return (
                  <motion.li
                    key={i}
                    title={t(item.title)}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.2, ease: 'easeOut' }}
                    // transition={{ type: 'spring', damping: 20, stiffness: 150 }}
                    onClick={() => {
                      navigate(item.link);
                    }}
                    data-sub_page_link_holder={
                      currentPath.includes(item.urlName) &&
                      currentPath.includes('tracking')
                        ? 'true'
                        : 'false'
                    }
                    data-is_nested_link_holder={'true'}
                    className='sidebarTara__bottom--linkItem'
                  >
                    <div className='sidebarTara__bottom--linkItem-left'>
                      <CustomSVGs svg={item.icon} />

                      <motion.span
                        initial={{ opacity: 0 }}
                        animate={{ opacity: isSidebarMinimized ? 0 : 1 }}
                        transition={{ duration: 1 }}
                      >
                        {t(item.title)}
                      </motion.span>
                    </div>
                  </motion.li>
                );
              })}
          </AnimatePresence>
        </>
      );
    } else {
      return null;
    }
  };

  const recommendationsSectionView = () => {
    if (!allRoutes['/tracking']) return null;

    if (activeNestedMenuLink !== 'settings') {
      return (
        <AnimatePresence>
          {linksRecommendations?.map((item, i) => {
            return (
              <motion.li
                title={t(item.title)}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.2, ease: 'easeOut' }}
                // transition={{ type: 'spring', damping: 20, stiffness: 150 }}
                onClick={() => {
                  navigate(item.link);
                }}
                data-sub_page_link_holder={
                  item.specialURL
                    ? currentPath === item.specialURL
                    : currentPath.includes(item.urlName)
                    ? 'true'
                    : 'false'
                }
                key={i}
                className='sidebarTara__bottom--linkItem'
              >
                <div className='sidebarTara__bottom--linkItem-left'>
                  {item?.iconType === 'svg' ? (
                    <CustomSVGs svg={item.icon} />
                  ) : (
                    <img
                      src={item.icon as any}
                      className='subPageLinkHolderItems__img'
                      alt='icon'
                    />
                  )}
                  <motion.span
                    initial={{ opacity: 0 }}
                    animate={{ opacity: isSidebarMinimized ? 0 : 1 }}
                    transition={{ duration: 1 }}
                  >
                    {t(item.title)}
                  </motion.span>
                </div>
              </motion.li>
            );
          })}
        </AnimatePresence>
      );
    } else {
      return null;
    }
  };

  const settingsSectionView = () => {
    if (!allRoutes['/settings']) return null;

    if (activeNestedMenuLink !== 'settings') {
      return (
        <>
          <div className='sidebarTara__bottom--title2'>
            <span>{t('Settings')}</span>
          </div>
          <AnimatePresence>
            <motion.li
              title={t('Settings')}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.2, ease: 'easeOut' }}
              // transition={{ type: 'spring', damping: 20, stiffness: 150 }}
              onClick={() => {
                navigate('/settings');
              }}
              data-sub_page_link_holder={
                currentPath.includes('settings') ? 'true' : 'false'
              }
              className='sidebarTara__bottom--linkItem'
            >
              <div className='sidebarTara__bottom--linkItem-left'>
                <CustomSVGs svg={settingsPlusSVG} />

                <motion.span
                  initial={{ opacity: 0 }}
                  animate={{ opacity: isSidebarMinimized ? 0 : 1 }}
                  transition={{ duration: 1 }}
                >
                  {t('Settings')}
                </motion.span>
              </div>
            </motion.li>
          </AnimatePresence>
        </>
      );
    } else {
      return null;
    }
  };

  const RenderLogo = () => {
    if (
      merchantTheme.merchant_logo_light &&
      merchantTheme.merchant_logo_dark &&
      merchantTheme.merchant_name === 'Buckaroo'
    ) {
      return (
        <img
          src={`${staticURL}/medium/${
            isDarkMode
              ? merchantTheme.merchant_logo_light
              : merchantTheme.merchant_logo_dark
          }`}
          alt='logo'
        />
      );
    } else if (
      merchantTheme.merchant_logo_dark &&
      merchantTheme.merchant_logo_light
    ) {
      return (
        <img
          src={`${staticURL}/medium/${
            isDarkMode
              ? merchantTheme.merchant_logo_light
              : merchantTheme.merchant_logo_dark
          }`}
          alt='logo'
        />
      );
    } else {
      return (
        <img
          style={{ borderRadius: '1rem' }}
          src={`${staticURL}/medium/default.jpg`}
          alt='logo'
        />
      );
    }
  };

  const getProfileName = () => {
    if (userProfile?.first_name || userProfile?.last_name) {
      return `${userProfile?.first_name} ${userProfile?.last_name}`;
    } else if (userProfile?.email) {
      return `${userProfile.email}`;
    } else {
      return t('User');
    }
  };

  const renderNormalSideBar = () => {
    return (
      <ul className='sidebarTara__bottom'>
        <div className='sidebarTara__bottom--links'>
          {/* Tara Section */}
          {taraSectionView()}

          {/* Dashboard Section */}
          {dashboardSectionView()}

          {/* AI Checkout Tracking Section */}
          {trackingSectionView()}

          {/* Recommendations Section */}
          {recommendationsSectionView()}

          {/* Main Menu Section */}
          {mainSectionView()}
        </div>
        <div
          style={{ marginTop: '2rem' }}
          className='taraHistorySidebar--title1'
        >
          <span>{t('RecentChats')}</span>
        </div>
        <div className='sidebarTara__bottom--history'>
          <TaraHistorySidebar isSidebarMinimized={isSidebarMinimized} />
        </div>
        {/* <div className='sidebarTara__bottom--title1'>
          <span>
            {currentPath.includes('settings') && t('Settings')}
            {!currentPath.includes('settings') && t('MainMenu')}
          </span>
        </div> */}

        {/* Settings Section*/}
        {/* {settingsSectionView()} */}
      </ul>
    );
  };

  return (
    <div
      data-is-sidebar-minimized={isSidebarMinimized?.toString()}
      className='sidebarTara'
    >
      <div className='sidebarTara__top'>
        <div className='sidebarTara__top--left'>
          {RenderLogo()}
          {/* <h2>{userCompany.name}</h2> */}
        </div>
        <div onClick={changeSidebarSize} className='sidebarTara__top--right'>
          <CustomSVGs svg={ToggleSideBarSVG} />
        </div>
      </div>
      <TaraCreateNewChat isSidebarMinimized={isSidebarMinimized} />

      {renderNormalSideBar()}

      <div
        data-is-sidebar-profile-open={isDropdownOpen.toString()}
        className='sidebarTara__profile'
      >
        <div
          className='sidebarTara__profile--content'
          onClick={toggleDropdown}
          ref={dropdownRef}
        >
          <div className='sidebarTara__profile--content-left'>
            <CustomSVGs svg={userSVG} />
            <span title={getProfileName()}>
              {truncate(getProfileName(), 29)}
            </span>
          </div>
          <div className='sidebarTara__profile--content-right'>
            <CustomSVGs svg={arrowHeadLeftSVG} />
          </div>
          <AnimatePresence>
            {isDropdownOpen && <SidebarProfileTheme2 />}
          </AnimatePresence>
        </div>
      </div>
    </div>
  );
};

export default SidebarTara;
