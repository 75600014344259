import { useTranslation } from 'react-i18next';
import '../../../styles/pages/checkoutTracking/recommendationPackages.scss';
import { useState } from 'react';
import RecommendationPackageItem from './RecommendationPackageItem';
import CustomSVGs from '../../../ui/customSVGs/CustomSVGs';
import { tickWithCircleSVG } from '../../../lib/allCustomSVGs';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import { useNavigate } from 'react-router-dom';
import { setIsPaymentPopupOpen } from '../../../store/reducers/uiSlice';
import {
  setCurrentChatID,
  setIsBotTyping,
} from '../../../store/reducers/chatSlice';

type Props = {
  taraSubscriptionPackages: any[];
};

const RecommendationPackages = ({ taraSubscriptionPackages }: Props) => {
  const { t } = useTranslation();
  const [isMonthly, setIsMonthly] = useState<boolean>(true);
  const [isSupportPopupOpen, setIsSupportPopupOpen] = useState<boolean>(false);
  const taraSubscriptionDetails = useSelector(
    (state: RootState) => state.user?.shop?.taraSubscriptionDetails
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();
  return (
    <>
      {isSupportPopupOpen ? (
        <div className='recommendationPackages--support'>
          <h3>{t('EmailSentSuccessfully')}</h3>
          <CustomSVGs svg={tickWithCircleSVG} />
        </div>
      ) : (
        <div className='recommendationPackages'>
          <div className='recommendationPackages__titles'>
            <h2>{t('Plans&Prices')}</h2>
            <p>{t('ChoosePlan-subtext')}</p>
          </div>
          {taraSubscriptionDetails &&
          taraSubscriptionDetails?.currentSubscriptionStatus === 'none' ? (
            <div className='recommendationPackages__banner'>
              <div className='recommendationPackages__banner--left'>
                <h3>{t('TaraPackageBannerText1')}</h3>
                <p>{t('TaraPackageBannerText2')}</p>
                <p>{t('TaraPackageBannerText3')}</p>
              </div>
              <div
                onClick={() => {
                  dispatch(setCurrentChatID(null));
                  dispatch(setIsBotTyping(false));
                  navigate('/tara');
                  dispatch(setIsPaymentPopupOpen(false));
                }}
                className='recommendationPackages__banner--right'
              >
                <span>{t('StartNow')}</span>
              </div>
            </div>
          ) : null}
          <div className='recommendationPackages__selection'>
            <div className='recommendationPackages__selection--text'>
              {t('PayMonthly')}
            </div>
            <div className='recommendationPackages__selection--toggle'>
              <input
                className='recommendationPackages__selection--toggle-input'
                type='checkbox'
                id='mode-toggle'
                checked={!isMonthly}
                onChange={() => setIsMonthly(!isMonthly)}
              />
              <label
                className='recommendationPackages__selection--toggle-label'
                htmlFor='mode-toggle'
              ></label>
            </div>

            <div className='recommendationPackages__selection--text'>
              {t('PayAnnually')}
            </div>
            <div className='recommendationPackages__selection--info'>
              {`10%  ${t('Cheaper')}`}
            </div>
          </div>
          <div className='recommendationPackages__items'>
            {taraSubscriptionPackages?.map((packageDetails) => (
              <RecommendationPackageItem
                key={packageDetails.id}
                isMonthly={isMonthly}
                packageDetails={packageDetails}
                setIsSupportPopupOpen={setIsSupportPopupOpen}
              />
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default RecommendationPackages;
