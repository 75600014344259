import { Route, Routes } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import SubPageInnerContentHolder from '../../components/subPageContentHolder/SubPageInnerContentHolder';
import { allRoutes } from '../../lib/constants/route';
import Trends from './Trends/Trends';
import PageWrapperMain from '../../components/page-wrappers/PageWrapperMain';
import SubPageContentHolder from '../../components/subPageContentHolder/SubPageContentHolder';

interface ApiDataType {
  api_key: string;
  [key: string]: any;
}

function Recommendations() {
  const { t } = useTranslation();

  return (
    <PageWrapperMain>
      <div className='subPage'>
        <SubPageContentHolder>
          <SubPageInnerContentHolder title={t('Recommendations')}>
            <Routes>
              {allRoutes['/recommendations'] && (
                <Route path='*' element={<Trends />} />
              )}
            </Routes>
          </SubPageInnerContentHolder>
        </SubPageContentHolder>
      </div>
    </PageWrapperMain>
  );
}

export default Recommendations;
